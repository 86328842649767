import React from "react";
import { SdfPromoBox, SdfBusyIndicator, SdfLink } from "@synerg/react-components";
import { useElementIsVisible } from "../../hooks/useIsElementVisible";
import { LoadHtmlFromString } from "./LoadHtmlFromString";
import { useEffect, useState, useRef } from "react";
import {useSelector} from "react-redux";
import { TableOfContents } from "./TableOfContents";
import { ShouldLoginBox } from "../ShouldLoginBox";
import useMediaQuery from "../../hooks/useMediaQuery";

export function GuideContent({ content }) {
	const loaderRef = useRef()
	const [lastChapterLoaded, setLastChapterLoaded] = useState(false);
	const [currentChapter, setCurrentChapter] = useState(0);
	const [isLoading, setIsLoading] = useState(false);

	  const isLoggedIn = useSelector((state) => {
		return state.auth.isLoggedIn;
     });

		 useEffect(()=>{
			setCurrentChapter(1)
			}, [])
		

	const lastElementIsVisible = useElementIsVisible(loaderRef.current, currentChapter);
  const isSM = useMediaQuery('(max-width: 600px)')
	const PDF =  (content?.isPublic || isLoggedIn) && content?.pdfLink &&
	<SdfLink className="cursor-pointer no-underline text-action-primary" emphasis="primary"
			external={true}
			href={content.pdfLink} target="_blank">
			Download Guide
		</SdfLink>;

	useEffect(() => {
		if (lastElementIsVisible) {

			if (currentChapter < content?.chaptersSections?.length) {
				setCurrentChapter(old => old + 1)
			}

			if (currentChapter >= content?.chaptersSections?.length) {
				setLastChapterLoaded(true)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lastElementIsVisible])



	return (
		<>
			{isLoading &&
				<SdfBusyIndicator
					style={{ color: "#1a8099" }}
					size="lg"
					overlay={true}
					fullScreen={false}
				/>
			}
			<div className="mx-4">
				<>{currentChapter >=1 &&
					<div className="flex">
						<div>
							<div className="flex mt-1 gap-3">
								<section id="guideContent" className="pb-3">
									<div id="guideTitle" className="text-3xl font-bold my-4">{content.title}</div>
									<SdfPromoBox
										size='sm'
										spacing="default"
										layout="horizontal"
										align='left'
										variant='filled'
										header='Summary'
										message={content.abstract}
										illustration-name='documents'
									/>
									{
										isSM && <div className="mt-6">{PDF}</div>
									}
									{!content?.isPublic && !isLoggedIn &&
                            <div className="flex pt-4 flex-col justify-center items-center">
                                <ShouldLoginBox />
                            </div>
                  }

									<div id="doc-preview">
										{content?.chaptersSections.map((chapter, index) => {
											if (index <= currentChapter - 1) {
												return <LoadHtmlFromString
													key={index}
													htmlStr={chapter.content}
												/>
											} else {
												return null;
											}
										})}
									</div>
									<footer id="guideContentFooter"></footer>
								</section>
							</div>
						</div>
						<div className="flex flex-col">
							<div className="flex flex-col gap-2 sticky" style={{ top: '5.50rem' }}>
								<aside className="mx-8">
								{!isSM && 
                     PDF
								}
									<TableOfContents
										currentChapter={currentChapter}
										currentGuideSections={content?.chaptersSections}
										setCurrentChapter={setCurrentChapter}
										setIsLoading={setIsLoading}
									/>
								</aside>
							</div>

						</div>
					</div>
				}</>
				{!lastChapterLoaded && <SdfBusyIndicator ref={loaderRef} />}
			</div>
		</>
	)

}

