import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { SdfLink } from "@synerg/react-components";
import { removeExtraSpacesFromString } from "../../utility/stringUtil";
import useMediaQuery from "../../hooks/useMediaQuery";


const CONTENT = '-ml-px cursor-pointer font-bold no-underline hover:underline text-action-primary border-solid rounded-none p-3 text-md border-r-0 border-b-0 border-t-0 border-4 -mr-3 py-3 border-transparent'
const SELECTED_CONTENT = CONTENT.replace('border-transparent', '')

function generateUrlId(str) {
    return str?.replaceAll(' ', '-').toLowerCase()
}

export function TableOfContents({ currentChapter, currentGuideSections, setCurrentChapter, setIsLoading }) {
    function scrollToElement(element, offsetTopMargin = 80, behavior = 'smooth', container = null) {
        if (!element) return

        const containerElement = container || window
        containerElement.scrollTo({
            behavior,
            top: (element.offsetTop - offsetTopMargin)
        })
    }
    const getArticleContents = useCallback((content) => {
        let contents = content?.querySelectorAll('*')
        if (!contents) return [];
        let count = 1

        return Array.from(contents)
            .filter((tag) => (tag.innerText && tag.innerText.trim() !== ''))
            .map((tag, index) => {
                if (tag.tagName === 'H3') {
                    tag.className = `h3-${count}`
                    count++
                }
                tag.id = index
                return tag
            })
            || [];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentChapter, currentGuideSections])

    const filterByTag = (arr, tagName) => arr.filter((tag) => tag.tagName === tagName)

    const h3sList = useMemo(() => {
        const parser = new DOMParser()
        const sectionsAsString = currentGuideSections.map(section => section.content).join(',')

        const html = parser.parseFromString(sectionsAsString, 'text/html');

        return Array.from(html.body.querySelectorAll('h3')).filter(ele =>ele.innerHTML.trim() !== '&nbsp;')
        .map((ele, index) => {
            const id = `h3-${index + 1}`;
            ele.id = id;
            return ele
        })

    }, [currentGuideSections])

    const isLgOrBigger = useMediaQuery('(min-width: 1024px)')
    const [renderContentsMenu, setRenderContentsMenu] = useState(false)
    const [highlighted, setHighlighted] = useState()
    const highlightedRef = useRef()
    let content = document.getElementById('doc-preview')
    let contentsElementsArray = getArticleContents(content)
    let h3s = filterByTag(contentsElementsArray, 'H3')
    const scrollOffsetTopMargin = 90;

    function getInitialHighlighted() {
        return h3sList[0]
    }

    function customSetHighlighted(element) {
        if (!element) return

        //verify if its a element from content and not from toc
        if (element.className) {
            element = document.getElementById(element.className)
        }

        setHighlighted(element)
        highlightedRef.current = element

        const toc = document.querySelector(".table-of-contents")
        if (!toc) return

        const ele = toc.querySelector(`[id='${element.id}']`);
        scrollToElement(ele, 80, 'smooth', toc)
    }

    function updateInitialHighlighted(element) {
        if (!element) return;

        customSetHighlighted(element)
        highlightedRef.current.urlId = generateUrlId(highlightedRef.current?.innerText)

        const initialHighlighted = document.getElementById('guideContent')
        scrollToElement(initialHighlighted, scrollOffsetTopMargin, 'instant')
    }

    function updateMarginBottom() {
        if (!h3sList?.length) return;

        contentsElementsArray = getArticleContents(content)
        h3s = filterByTag(contentsElementsArray, 'H3')
        const lastH3 = h3s[h3s.length - 1]
        const lastH3Id = parseInt(lastH3.id)

        let noPrintElements = 0;
        let lastBelowLastH3;
        Array.from(document.getElementById("guideContentFooter") || [])
            .forEach((elm) => {
                if (elm.offsetHeight > 500) return
                noPrintElements += elm.offsetHeight
            }
            )
        contentsElementsArray.forEach((elm) => {
            if (lastH3Id > parseInt(elm.id)) return
            lastBelowLastH3 = elm
        })
        const lastH3Rect = lastH3.getBoundingClientRect();
        const belowLastH3Rect = lastBelowLastH3.getBoundingClientRect();

        let sum =
            // window
            window.innerHeight
            // header and footer
            - noPrintElements
            // distance between h3 and last element
            - (belowLastH3Rect.bottom - lastH3Rect.bottom)

        if (!lastH3?.parentElement?.parentElement?.style) return
        sum = sum + (20)
        lastH3.parentElement.style.marginBottom = (sum <= 0 || !lastH3?.parentElement?.style) ? '0' : sum + 'px'

    }

    useEffect(() => {
        updateInitialHighlighted(
            getInitialHighlighted()
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentGuideSections])

    useEffect(() => {
        setRenderContentsMenu(true)
    }, [])

    const handleContentsHighlighting = useCallback(() => {
        contentsElementsArray = getArticleContents(content)
        let filtered = contentsElementsArray.filter((elm) => {
            const rect = elm.getBoundingClientRect()
            return (
                rect.left >= 0 &&
                rect.top >= 66 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            )
        })

        if (!(filtered && filtered.length > 0)) return

        const filteredClassName = filtered[0].className;
        const highlightedId = highlightedRef.current?.id

        if (
            filtered[0].tagName === 'H3' &&
            filteredClassName !== highlightedId
        ) {
            customSetHighlighted(filtered[0])
        }
        else if (filteredClassName === highlightedId) {
            customSetHighlighted(filtered[0])
        }

    }, [currentChapter, currentGuideSections])


    useEffect(() => {
        document.addEventListener('scroll', handleContentsHighlighting)

        return () => {
            document.removeEventListener('scroll', handleContentsHighlighting)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentChapter, currentGuideSections])

    useEffect(() => {
        if (currentChapter === currentGuideSections.length) {
            setTimeout(() => {
                updateMarginBottom()
            }, 500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentChapter, currentGuideSections])

    function loadNextChapterUntilFindElement(id) {
        const [elementOnDom] = document.getElementsByClassName(id)

        if (elementOnDom) {
            scrollToElement(elementOnDom, scrollOffsetTopMargin)
        } else {
            setIsLoading(true)
            let timer = setInterval(() => {
                const [foundedElement] = document.getElementsByClassName(id)

                if (foundedElement) {
                    setIsLoading(false)
                    setTimeout(() => {
                        scrollToElement(foundedElement, scrollOffsetTopMargin)
                    }, 500)
                    clearInterval(timer)
                }
                if (currentChapter < currentGuideSections.length) setCurrentChapter(old => old + 1)
            }, 700)
        }
    }

    function handleContentMenuByClick(id) {
        loadNextChapterUntilFindElement(id)

    }

    function handleContentMenuByKeyPress(id, e) {
        if (e.key === 'Enter' && e.charCode === 13) {
            loadNextChapterUntilFindElement(id)
        }
    }

    if (!isLgOrBigger) return null

    return (
        <>
            {
                renderContentsMenu &&
                    h3sList.length > 0 ? (
                    <div className="w-xs mb-6 mt-8 pl-6 pt-6 pr-2 pb-2 sticky bg-alert-neutral-50 table-of-contents">
                        <div id="toc">
                            <div className="mb-3 text-lg font-bold">Contents</div>
                            <div className={`flex flex-col `}>
                                {
                                    h3sList.map((item, index) => {
                                        return <SdfLink
                                            key={index}
                                            id={item.id}
                                            className={highlighted?.id === item.id ? SELECTED_CONTENT : CONTENT}
                                            onClick={() => handleContentMenuByClick(item.id)}
                                            onKeyPress={(e) => handleContentMenuByKeyPress(item.id, e)}
                                        >
                                            {removeExtraSpacesFromString(item.innerText)}
                                        </SdfLink>
                                    })
                                }
                            </div>
                        </div>
                    </div>

                ) :
                    <div className="md:w-52 ml-16"></div>
            }
        </>

    )
}