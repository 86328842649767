import React, { useEffect } from "react";
import DOMPurify from "dompurify";
import './relatedGuides.scss';
import { findElements } from "../../utility/DOMUtils";

const resetOptions = {
    'TABLE': (table) => {
        table.removeAttribute('border')
        table.cellSpacing = 0
        table.cellPadding = 0
        const hasTHead = !!table.querySelector('thead')
        table.classList.add(hasTHead ? 'table-with-thead' : 'table-without-thead')
    },
    'PRE': (pre) => {
        const sdfBox = document.createElement('div');
        sdfBox.className = "bg-action-default-700 p-5 m-5 ml-0 rounded-xl text-default"
        pre.parentNode.insertBefore(sdfBox, pre);
        sdfBox.appendChild(pre);
    },
    'CODE': (code) => {
        code.className = "leading-xl"
        code.style.color = "white"
    }
}

export function LoadHtmlFromString({ htmlStr, className = '' }) {
    const resetInlineStyle = (elements, elementsToReset) => {
        elements.forEach(element => {
            const docPreviewElements = element.querySelectorAll(elementsToReset)
            docPreviewElements.forEach(docPreviewElement => {
                docPreviewElement.removeAttribute('style')
                resetOptions[docPreviewElement.tagName]?.(docPreviewElement)
            })

        })
    }

    useEffect(() => {
        const elements = findElements('#doc-preview')
        elements.length > 0 && resetInlineStyle(elements, 'table,p,pre,code')
    }, [htmlStr])

    return (
        <div className={`dev-portal-custom-style ${className}`}>
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(htmlStr) }} />
        </div>
    )
}